import { React } from "react"
import { useSetRecoilState  } from "recoil";
import { orderState } from "../atoms/store";
import { getShoppingCart,setProductOrder } from '../lib/api/cart';
import { getCartUUID } from '../lib/utils/cart'
import { setCookies, removeCookies } from 'cookies-next';
import { notifyMe } from "../helper";

export default function withCart(BaseComponent) {
    const App = (props) => {                    
        const setOrders = useSetRecoilState(orderState);

        const findCart = async () => {                    
            try {
              const cartUUID = getCartUUID();
              const cartResponse = await getShoppingCart(cartUUID);
            
              if(cartResponse.success) {
                  setOrders(cartResponse.data);
                  removeCookies('cartUuid', { path: '/', domain: 'eccediciones.com' });
                  removeCookies('cartUuid')
              }
            } catch (error) {
               /** SENTRY **/
            }
        }

        const addProductsToCart = async (product_id, qty) => {
            try {
                const cartUuid = getCartUUID();
                const productToCart = {
                    "productId": product_id,
                    "qty": qty ? qty : 1,
                    "cartUuid": cartUuid
                }
                const resp = await setProductOrder(productToCart);
                if(resp.success) {
                    setOrders(resp.data)
                    const CartUUIdToken = resp.data.order.uuid;
                    if(CartUUIdToken.includes("ANON")) {
                        setCookies('cartUuid', resp.data.order.uuid);
                    } else {
                        removeCookies('cartUuid');
                    }
                    notifyMe({ key: 'myspace', method: 'success', message: 'Producto añadido al carrito.'});
                } else {
                    notifyMe({ key: 'myspace', method: 'error', message: 'Error al añadir el producto al carrito'});
                }
            } catch (error) {
                /** SENTRY **/
            }
        }
        

        return (                           
            <BaseComponent {...props} findCart={findCart} addProductsToCart={addProductsToCart}/>
            
        );        
    }
    
    return App
}