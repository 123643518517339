import React from "react";
import withCart from "../../lib/withCart";

const BuyButton = ({addProductsToCart, available, productId, stockAvailable}) => {
    
    return (        
            <>  
                {available == 1 ? 
                <button className={`btn2${stockAvailable == 0 ? ' disabled' :''}`} disabled={stockAvailable == 0} onClick={() => addProductsToCart(productId)}>{stockAvailable == 0 ? "No disponible" : "Añadir"}</button>
                : 
                <button className={`btn2 reservar${stockAvailable ==0 ? ' disabled':''}`} disabled={stockAvailable == 0} onClick={() => addProductsToCart(productId)}>{stockAvailable == 0 ? "No disponible" : "Reservar"}</button>}                
            </>        
    );
}

export default withCart(BuyButton);