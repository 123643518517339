import React, { useEffect, useState } from "react";
import styles from './index.module.css';

const Discounts = () => {

    return (
        <>
            <div className={`container main-container ${styles.eccComicsDiscountsBody}`}>
                <div className={styles.discountWrapper}>
                    <div className={styles.discountContainer}>
                        <img src={"/assets/images/discounts.png"} />
                    </div>
                    <div className={styles.discountContainer}>
                        <img src={"/assets/images/discounts.png"} />
                    </div>
                    <div className={styles.discountContainer}>
                        <img src={"/assets/images/discounts.png"} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Discounts;
