import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styles from './index.module.css';
import Link from "next/link";
import BuyButton from "../../ProductsListing/BuyButton";
import ContentImage from "../../common/ContentImage";

const settings = {
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    rows: 1,
    dots: true,
    infinite: true,
    responsive: [
        {
            breakpoint: 1920,
            settings: {
                slidesToShow: 8,
                slidesToScroll: 8,
            }
        },
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                dots: true,
                infinite: true,
            }
        },
        {
            breakpoint: 1140,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: true,
                infinite: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                rows: 2,
                dots: true,
                infinite: true,
            }
        }
    ]
};



const Novedades = ({ data, title }) => {

    return (
        <>
            <div className={`container main-container ${styles.eccComicsNewsBody} ecc_comics_news_body_wrapper`}>
                <div className={styles.newsWrapper}>
                    <h2 className={styles.newsHeading}>{title}</h2>
                </div>
                <div className={styles.productListingWrapper}>
                    <Slider {...settings}>
                        {
                            data?.map((item, i) => (
                                <Link passHref={true} key={`noticia${i}`} href={`/foreign-rights/content/${item.slug}`}>
                                    <a className={styles.newsLink} target={item.newWindow == 1 ? '_blank' : '_self'}>
                                        <div className={styles.productLinkWrapper}>
                                            <div className={styles.imgWrapper}>
                                                <ContentImage imageName={item.image} isImageComponent={false} />
                                            </div>
                                            <div className={styles.productMeta}>
                                                <h1>{item?.title}</h1>
                                                <div className="" dangerouslySetInnerHTML={{ __html: item?.shortContent }} ></div>
                                                <label className={styles.productPrice}>{item.price}€</label>
                                                <div className={styles.buyButton}>
                                                    <BuyButton available={item?.available} productId={item?.id} stockAvailable={item?.stockAvailable}></BuyButton>
                                                </div>

                                            </div>
                                        </div>
                                    </a>
                                </Link>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default Novedades;
