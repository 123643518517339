import React, { useEffect, useRef, useState } from "react";
import Link from 'next/link';
import Image from 'next/image';
import dummy_image from  '../../public/assets/images/dummyimage.png';
import ecc_no_image from  '../../public/assets/images/ECC-NoImage.jpeg';
const ContentImage = (props) => {
    const [imageNameSRC,setImageNameSRC] = useState(process.env.NEXT_PUBLIC_IMAGE_PATH + props.imageName)    
    const [dummyImage, setDummyImage] = useState(dummy_image);

    useEffect(()=>{
        if(props.eccImage){
            setDummyImage(ecc_no_image)
        }
        setImageNameSRC(process.env.NEXT_PUBLIC_IMAGE_PATH + props.imageName);
    },[props.imageName])


    return (
        <>
        {
            props.isImageComponent === true ?
            <Image quality={100} src={imageNameSRC} alt="" layout="fill" onError={() => {
               setImageNameSRC(dummyImage);}}>                
            </Image>
            :
            <img src={imageNameSRC} alt=""  onError={() => {
                setImageNameSRC(dummyImage?.src);
            }}/>
        }
        </>
    );
}

export default ContentImage
