import React, { useEffect, useState } from "react";
import styles from './index.module.css';

const EccNewsletter = () => {
    return (
        <>
        <div className={`container-fluid ${styles.newsLetterWrapper}`}>
                <div className={`container main-container ${styles.newsLetter}`}>
                    <div>NewsLetter</div>
                    <div className={styles.socialMediaWrapper}>
                        <ul className={styles.socialMediaList}>
                            <li className="nav-item"><a rel="noreferrer" target="_blank" href="https://www.facebook.com/ECCEdiciones"><i className={`${styles.socialMediaIcon} fab fa-facebook-square`} aria-hidden="true"></i></a></li>
                            <li className="nav-item"><a rel="noreferrer" target="_blank" href="https://twitter.com/eccediciones"><i className={`${styles.socialMediaIcon} fab fa-x-twitter`} aria-hidden="true"></i></a></li>
									<li className="nav-item"><a rel="noreferrer" target="_blank" href="https://instagram.com/eccediciones"><i className={`${styles.socialMediaIcon} fab fa-instagram`} aria-hidden="true"></i></a></li>
									<li className="nav-item"><a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/CanalECCEdiciones"><i className={`${styles.socialMediaIcon} fab fa-youtube`} aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EccNewsletter;
