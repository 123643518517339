import React, { useEffect, useState } from "react";
import Novedades from "../Novedades";
import Discounts from "../Discounts";
import EccStores from "../Stores";
import EccNewsletter from "../Newsletter";


const EccComicsHome = () => {

    const mockData = [
        {
            "slug": "batman-el-tribunal-de-los-buho-dc-pocket-tercera-edicion",
            "image": "/productos/13964/cubiertas_BatmanSupermanAuthority_Especial.jpg",
            "title": "Batman: El tribunal de los Búhos (DC Pocket) (Tercera edición)",
            "available": true,
            "id": "batman-tribunal-buho-03",
            "totalLine": 1,
            "price": "19,95",
            "stockAvailable": 50
        },
        {
            "slug": "superman-origenes-dc-pocket",
            "image": "/productos/13964/cubiertas_BatmanSupermanAuthority_Especial.jpg",
            "title": "Superman: Orígenes (DC Pocket)",
            "available": true,
            "id": "superman-origenes-01",
            "totalLine": 1,
            "price": "17,95",
            "stockAvailable": 30
        },
        {
            "slug": "wonder-woman-el-poder-de-la-mujer-dc-pocket",
            "image": "/productos/13964/cubiertas_BatmanSupermanAuthority_Especial.jpg",
            "title": "Wonder Woman: El Poder de la Mujer (DC Pocket)",
            "available": true,
            "id": "wonder-woman-poder-02",
            "totalLine": 1,
            "price": "18,95",
            "stockAvailable": 10
        },
        {
            "slug": "flash-rebote-temporal-dc-pocket",
            "image": "/productos/13964/cubiertas_BatmanSupermanAuthority_Especial.jpg",
            "title": "Flash: Rebote Temporal (DC Pocket)",
            "available": true,
            "id": "flash-rebote-temporal-04",
            "totalLine": 1,
            "price": "20,00",
            "stockAvailable": 75
        }
    ]
    return (
        <>
            <Novedades data={mockData} title={'Novedades en cómics'} />
            <Novedades data={mockData} title={'Novedades en merchandising'} />
            <Discounts />
            <Novedades data={mockData} title={'Nuestras recomendaciones'} />
            <EccStores />
            <EccNewsletter />
        </>
    );
};

export default EccComicsHome;
