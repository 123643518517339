import React, { useEffect, useState } from "react";
import styles from './index.module.css';

const EccStores = () => {

    return (
        <>
            <div className={`container-fluid`}>
                <div className={styles.storeWrapper}>
                    <img src="/assets/images/storeBG.png" alt="" />
                    <div className={styles.eccComicsStoreBody}>
                    <div className="container main-container">
                            <div className={styles.storeContent}>
                                <h3>Consulta nuestras tiendas y horarios</h3>
                                <h1>Más de cien mil productos únicos.
                                    <br/>
                                    Encuentra el tuyo.
                                </h1>
                                <div className={styles.storeLocation}>
                                    <a className={styles.storeName}>Al​icante</a>
                                    <a className={styles.storeName}>Al​icante</a>
                                    <a className={styles.storeName}>Al​icante</a>
                                    <a className={styles.storeName}>Al​icante</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EccStores;
