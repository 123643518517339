import sendRequest from './sendRequest';

const BASE_PATH = 'api';

export const getHomeData = (ctx) =>
    sendRequest(`${BASE_PATH}/banner/GetHomeBanners`, {method: 'GET'}, ctx);

export const getHomeReleases = data =>
    sendRequest(`${BASE_PATH}/content/GetHomeReleases?ShopId=${1}`, {
        method: 'GET',
    });

export const getHomeNews = data =>
    sendRequest(`${BASE_PATH}/content/GetHomeNews`, {
        method: 'GET',
    });

export const getHomeSchedule = data =>
    sendRequest(`${BASE_PATH}/Schedule/GetHomeSchedule?PageNumber=${data}&limit=36`, {
        method: 'GET',
    });

export const getHomeEccMagzine = data =>
    sendRequest(`${BASE_PATH}/Products/GetHomeEccMagazine?limit=1`, {
        method: 'GET',
    });

export const gethomeProductNews = data =>
    sendRequest(`${BASE_PATH}/Products/GetPoductsHomeNews`, {
        method: 'GET',
    });
export const getHomePublishingLines = (ctx) =>
	sendRequest(`${BASE_PATH}/PublishingLines/GetHomePublishingLines`, {method: 'GET',}, ctx);


export const getHomeCommunicadosBydetail = data =>
    sendRequest(`${BASE_PATH}/content/GetContentDetail?Slug=${data.slug}&previewToken=${data.tokenId}`, {
        method: 'GET',
    });
