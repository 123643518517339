import sendRequest from './sendRequest';

const BASE_PATH = 'api';

export const getHomescheduledetails = data =>
    sendRequest(`${BASE_PATH}/schedule/GetEventDetails?id=${data}`, {
        method: 'GET',
    });

export const getHomecontentdetails = data =>
    sendRequest(`${BASE_PATH}/content/GetContentCoverList?${data}`, {
        method: 'GET',
    });

export const getContentRelease = data =>
    sendRequest(`${BASE_PATH}/Content/GetLastRelese`, {
        method: 'GET',
    });