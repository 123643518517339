import { notification } from 'antd';

export const notifyMe = (props) => {
    const { key = '', method = 'success', message = '', description = '', duration = 2  } = props;
    switch(method) {
        case "success":
            notification.success({ key, message, description, duration, ...props });
            break;
        case "error":
            notification.error({ key, message, description, duration, ...props });
            break;
        case "info":
            notification.info({ key, message, description, duration, ...props });
            break;
        case "warning":
            notification.warning({ key, message, description, duration, ...props });
            break;
        case "close":
            notification.close(key);
            break;
    }
};